import { PERMISSIONS } from '../utils/permission'
import { PATHNAME } from './routes'

export interface INavigation {
  key: keyof typeof PERMISSIONS
  label: string
  navLink: string
  query?: string
  numberNoti?: number
  Icon?: JSX.Element
  children?: INavigation[] | null
}

export const bussinessNavigation: INavigation[] = [
  {
    key: 'ASSET',
    label: 'Assets',
    navLink: `/${PATHNAME.assets}`,
    Icon: <span className='font-icon-device' />,
  },
  {
    key: 'ASSET_GROUP',
    label: 'Asset Groups',
    navLink: `/${PATHNAME['asset-groups']}`,
    Icon: <span className='font-icon-device' />,
  },
  {
    key: 'PRODUCT',
    label: 'Products',
    navLink: `/${PATHNAME.products}`,
    Icon: <span className='font-icon-box' />,
  },
  // {
  //   key: 'purchase-orders',
  //   label: 'Purchase Orders',
  //   navLink: '/',
  //   Icon: <span className='font-icon-cart' />,
  // },
  // {
  //   key: 'credit-note',
  //   label: 'Credit Note',
  //   navLink: '/',
  //   Icon: <span className='font-icon-card text-[0.75rem]' />,
  // },
  {
    key: 'EXPENSE',
    label: 'Expenses',
    navLink: `/${PATHNAME.expenses}`,
    Icon: <span className='font-icon-moneybag' />,
  },
  {
    key: 'FILE',
    label: 'Files',
    navLink: `/${PATHNAME.files}`,
    Icon: <span className='font-icon-attachment' />,
  },
]

export const managementNavigation: INavigation[] = [
  // {
  //   key: 'tasks',
  //   label: 'Tasks',
  //   navLink: '/',
  //   Icon: <span className='font-icon-spec' />,
  // },
  {
    key: 'SERVICE',
    label: 'Service Management',
    navLink: `/${PATHNAME.services}`,
    Icon: <span className='font-icon-ticket' />,
  },
  {
    key: 'MONITORING',
    label: 'Monitoring',
    navLink: `/${PATHNAME.monitorings}`,
    Icon: <span className='font-icon-view text-[0.6875rem]' />,
  },
  // {
  //   key: 'inspections',
  //   label: 'Inspections',
  //   navLink: '/',
  //   Icon: <span className='font-icon-search' />,
  // },
  {
    key: 'TIMESHEET',
    label: 'Timesheets',
    navLink: `/${PATHNAME.timesheets}`,
    Icon: <span className='font-icon-calendar' />,
  },
  // {
  //   key: 'reporting',
  //   label: 'Reporting',
  //   navLink: '/',
  //   Icon: <span className='font-icon-chart' />,
  // },
  {
    key: 'MOVEMENT_TRACKING',
    label: 'Movement Tracking',
    navLink: `/${PATHNAME['movement-tracking']}`,
    Icon: <span className='font-icon-location' />,
  },
  {
    key: 'FLEET_TRACKING',
    label: 'Fleet Tracking',
    navLink: `/${PATHNAME['fleet-tracking']}`,
    Icon: <span className='font-icon-location' />,
  },
  {
    key: 'PLANSET',
    label: 'Plan Set',
    navLink: `/${PATHNAME['plan-set']}`,
    Icon: <span className='font-icon-map' />,
  },
  {
    key: 'PERMITS',
    label: 'Permits',
    navLink: `/${PATHNAME['permits']}`,
    Icon: <span className='font-icon-document' />,
  },
  {
    key: 'WHATSAPP_MESSAGE',
    label: 'WhatsApp Inbound',
    navLink: `/${PATHNAME['uncategorized']}`,
    Icon: <span className='font-icon-whatsapp' />,
  },
]

export const otherNavigation: INavigation[] = [
  {
    key: 'USER',
    label: 'Users',
    navLink: `/${PATHNAME.users}`,
    Icon: <span className='font-icon-user' />,
  },
  {
    key: 'ROLE',
    label: 'User Roles',
    navLink: `/${PATHNAME.roles}`,
    Icon: <span className='font-icon-user' />,
  },
  {
    key: 'SETTING',
    label: 'Settings',
    navLink: `/${PATHNAME.settings}`,
    Icon: <span className='font-icon-setting' />,
  },
]

export const salePipeLineNavigation: INavigation[] = [
  // {
  //   key: 'dashboard',
  //   label: 'Dashboard',
  //   navLink: '/',
  //   Icon: <span className='font-icon-calculate' />,
  // },
  // {
  //   key: 'leads',
  //   label: 'Leads',
  //   navLink: '/',
  //   Icon: <span className='font-icon-flag' />,
  // },
  {
    key: 'OPPORTUNITY',
    label: 'Opportunities',
    navLink: `/${PATHNAME.opportunities}`,
    Icon: <span className='font-icon-sparkle' />,
  },
  {
    key: 'QUOTE',
    label: 'Quotes',
    navLink: `/${PATHNAME.quotes}`,
    Icon: <span className='font-icon-document' />,
  },
  {
    key: 'PROJECT',
    label: 'Projects',
    navLink: `/${PATHNAME.projects}`,
    Icon: <span className='font-icon-rocket' />,
  },
  // {
  //   key: 'estimates',
  //   label: 'Estimates',
  //   navLink: '/',
  //   Icon: <span className='font-icon-clock' />,
  // },
  {
    key: 'ACCOUNT',
    label: 'Accounts',
    navLink: `/${PATHNAME.accounts}`,
    Icon: <span className='font-icon-calculate' />,
    // children: [
    //   {
    //     key: 'customers',
    //     label: 'Customers',
    //     navLink: '/customers',
    //   },
    //   {
    //     key: 'vendors',
    //     label: 'Vendors',
    //     navLink: '/vendors',
    //   },
    // ]
  },
  {
    key: 'ACCOUNT_CUSTOMER',
    label: 'Customers',
    navLink: `/${PATHNAME.accounts}/customers`,
    Icon: <span className='font-icon-happy_face' />,
  },
  {
    key: 'ACCOUNT_LEAD',
    label: 'Leads',
    navLink: `/${PATHNAME.accounts}/leads`,
    Icon: <span className='font-icon-happy_face' />,
  },
  {
    key: 'ACCOUNT_VENDOR',
    label: 'Vendors',
    navLink: `/${PATHNAME.accounts}/vendors`,
    Icon: <span className='font-icon-store' />,
  },
  {
    key: 'CONTACT',
    label: 'Contacts',
    navLink: `/${PATHNAME.contacts}`,
    Icon: <span className='font-icon-user' />,
  },
  {
    key: 'INVOICE',
    label: 'Invoices',
    navLink: `/${PATHNAME.invoices}`,
    Icon: <span className='font-icon-money' />,
  },
  {
    key: 'CONTRACT',
    label: 'Maintenance Contract',
    navLink: `/${PATHNAME.contracts}`,
    Icon: <span className='font-icon-wrench' />,
  },
]

export const navigations = [
  ...bussinessNavigation,
  ...managementNavigation,
  ...otherNavigation,
  ...salePipeLineNavigation,
]

export const defaultNavigation: INavigation[] = [
  {
    key: 'ACCOUNT',
    label: 'Accounts',
    navLink: `/${PATHNAME.accounts}`,
    // children: [
    //   {
    //     key: 'customers',
    //     label: 'Customers',
    //     navLink: '/customers',
    //   },
    //   {
    //     key: 'vendors',
    //     label: 'Vendors',
    //     navLink: '/vendors',
    //   },
    // ]
  },
  {
    key: 'PROJECT',
    label: 'Projects',
    navLink: `/${PATHNAME.projects}`,
  },
  {
    key: 'CONTRACT',
    label: 'Contracts',
    navLink: `/${PATHNAME.contracts}`,
  },
  {
    key: 'ASSET',
    label: 'Assets',
    navLink: `/${PATHNAME.assets}`,
  },
  {
    key: 'ASSET_GROUP',
    label: 'Asset Groupts',
    navLink: `/${PATHNAME['asset-groups']}`,
  },
  {
    key: 'PRODUCT',
    label: 'Products',
    navLink: `/${PATHNAME.products}`,
  },
  {
    key: 'SERVICE',
    label: 'Service Tickets',
    navLink: `/${PATHNAME.services}`,
  },
  {
    key: 'MOVEMENT_TRACKING',
    label: 'Movement Tracking',
    navLink: `/${PATHNAME['movement-tracking']}`,
  },
  {
    key: 'FLEET_TRACKING',
    label: 'Fleet Tracking',
    navLink: `/${PATHNAME['fleet-tracking']}`,
  },
  {
    key: 'EXPENSE',
    label: 'Expenses',
    navLink: `/${PATHNAME.expenses}`,
  },
  // {
  //   key: 'tickets',
  //   label: 'Service Tickets',
  //   navLink: '#',
  //   numberNoti: 37,
  //   children: [
  //     {
  //       key: 'ticket-1',
  //       label: 'Ticket 1',
  //       navLink: '#',
  //     },
  //     {
  //       key: 'ticket-2',
  //       label: 'Ticket 2',
  //       navLink: '/tickets/ticket-2',
  //     },
  //   ],
  // },
  // {
  //   key: 'leads',
  //   label: 'Leads',
  //   navLink: '#',
  // },
  {
    key: 'OPPORTUNITY',
    label: 'Opportunities',
    navLink: `/${PATHNAME.opportunities}`,
    // numberNoti: 94,
  },
  {
    key: 'QUOTE',
    label: 'Quotes',
    navLink: `/${PATHNAME.quotes}`,
    // numberNoti: 24,
  },
  {
    key: 'INVOICE',
    label: 'Invoices',
    navLink: `/${PATHNAME.invoices}`,
  },
  {
    key: 'USER',
    label: 'Users',
    navLink: `/${PATHNAME.users}`,
  },
]

export const customerNavigations: INavigation[] = [
  {
    key: 'QUOTE',
    label: 'Quotes',
    navLink: PATHNAME.quotes,
  },
  {
    key: 'INVOICE',
    label: 'Invoices',
    navLink: PATHNAME.invoices,
  },
  {
    key: 'PLANSET',
    label: 'Plan Set',
    navLink: PATHNAME['plan-set'],
  },
  {
    key: 'CUSTOMER_BILLING',
    label: 'Billing',
    navLink: PATHNAME['billing'],
  },
  // {
  //   key: 'FILE',
  //   label: 'Files',
  //   navLink: PATHNAME.files,
  // },
  // {
  //   key: 'SERVICE',
  //   label: 'Service Tickets',
  //   navLink: PATHNAME.services,
  // },
]
