export const PERMISSIONS_VIEW = 'view'
export const PERMISSIONS_VIEW_OWN = 'view own'
export const PERMISSIONS_UPDATE = 'update'
export const PERMISSIONS_DELETE = 'delete'
export const PERMISSIONS_CREATE = 'create'

export const PERMISSIONS = {
  USER: 'users',
  ROLE: 'roles',
  ACCOUNT: 'accounts',
  ACCOUNT_CUSTOMER: 'accounts',
  ACCOUNT_VENDOR: 'accounts',
  ACCOUNT_LEAD: 'accounts',
  OPPORTUNITY: 'opportunities',
  CONTACT: 'contacts',
  LEAD: 'leads',
  ALL_QUOTE: 'quotes',
  OWN_QUOTE: 'quotes',
  QUOTE: 'quotes',
  PROJECT: 'projects',
  TIMESHEET: 'timesheet entries',
  NOTE: 'notes',
  TAG: 'tags',
  FILE: 'files',
  EXPENSE: 'expenses',
  EXPENSE_CATEGORY: 'expense categories',
  TASK: 'tasks',
  ASSET: 'assets',
  INVOICE: 'invoices',
  OWN_INVOICE: 'invoices',
  SERVICE: 'service tickets',
  TAX_VENDOR: 'tax vendors',
  SETTING: 'setting labels',
  CONTRACT: 'maintenance contracts',
  PREVENTATIVE: 'preventative maintenance logs',
  PLANSET: 'plan sets',
  OWN_PLANSET: 'plan sets',
  PRODUCT: 'products',
  CMS: 'central monitoring stations',
  MONITORING: 'monitoring services',
  COMPANY: 'company settings',
  PROTECT_FILE: 'protected files',
  OWN_TIMESHEET: 'timesheet entries',
  GPS_DEVICE: 'gps devices',
  GPS_POSITION: 'gps positions',
  ASSIGNE_TASK: 'assigned tasks',
  ASSIGNE_PROJECT: 'assigned projects',
  SEND_SERVICE: 'send service ticket to customer',
  SEND_QUOTE: 'send quote',
  SEND_INVOICE: 'send invoice',
  WATCHER: 'watchers',
  ASSIGNE: 'assignees',
  CHECKOUT_ASSET: 'checkout assets',
  PAYMENT_SETTING: 'payment settings',
  TRANSACTION: 'transactions',
  ASSET_GROUP: 'asset groups',
  MOVEMENT_TRACKING: 'gps devices', //TODO: need check again
  FLEET_TRACKING: 'gps positions', //TODO: need check again
  CHANGE_LOG: 'activity logs',
  CALENDAR: 'embedded calendars',
  CUSTOMER_PORTAL: 'customer portal',
  CUSTOMER_BILLING: 'customer billing',
  WHATSAPP_MESSAGE: 'whatsapp chat messages',
  ACCOUNT_CREDENTIALS: 'account credentials',
  PERMITS: 'permits',
}

export const isHasPermission = (
  permissionName: string,
  listPermissions: string[] = [],
) => listPermissions.includes(permissionName)

export const checkMenuPermisison = (
  path: string,
  permissions?: string[] | null,
) => {
  if (!permissions || permissions.length === 0) {
    return false
  }
  return (
    isHasPermission(`${PERMISSIONS_VIEW} ${path}`, permissions) ||
    isHasPermission(`${PERMISSIONS_VIEW_OWN} ${path}`, permissions)
  )
}
